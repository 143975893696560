import React from "react"
import { RegularNav } from "./site-nav"
import Social from "./social"
import Instagram from "./instagram"

export default function Footer() {
  return (
    <>
      <Instagram />

      <footer className="flex justify-content-center">
        <div className="text-center">
          <RegularNav></RegularNav>
          <Social></Social>
          <p>Copyright © {(new Date()).getFullYear()} Well From Within LLC</p>
        </div>
      </footer>
    </>
  )
}
import { Link } from "gatsby"
import React from "react"
import Nav from "./site-nav"

export default function Header() {
  return (
    <header className="page-max-width p-y-1">
      <Link to="/"><img src="/assets/images/logo-resize.jpg"></img></Link>
      <Nav></Nav>
    </header>
  )
}
import { Link } from "gatsby"
import React from "react"

const links = [
  { url: '/about', name: 'About' },
  { url: '/services-and-rates', name: 'Services & Rates' },
  { url: '/contact', name: 'Contact' },
  { url: '/work-with-us', name: 'Work With Us' },
  { url: '/blog', name: 'Blog' },
]

export default function Nav() {
  return (
    <nav role='navigation'>
      <RegularNav></RegularNav>

      <div id="menuToggle">
        <input type="checkbox" />
        
        <span></span>
        <span></span>
        <span></span>
        
        <ul id="menu">
          {links.map(link => <li className="m-x-1" key={link.url}><Link activeClassName="active" to={link.url}>{link.name}</Link></li>)}
        </ul>
      </div>
     
    </nav>
  )
}

export function RegularNav() {
  return (<ul id="fullMenu" className="flex flex-justify-content-center">
    {links.map(link => <li className="m-x-1" key={link.url}><Link activeClassName="active" to={link.url}>{link.name}</Link></li>)}
  </ul>)
}
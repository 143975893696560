import React from "react"
import Header from "./header"
import {Helmet} from "react-helmet";
import Footer from "./footer";

export default function Layout({ children }: {children: JSX.Element}) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Well From Within | Freelance Health Writing & Blogging</title>
        <meta name="description" content="Freelance health writer specializing in nutrition, functional medicine, holistic health, naturopathic medicine, lifestyle medicine, and integrative health topics." />
        <link rel="icon" type="image/png" href="assets/images/favicon-32x32.png"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap" rel="stylesheet"></link>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-82148300-1"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-82148300-1');
          `}
        </script>
      </Helmet>
      <div className="body-wrapper">
        <Header></Header>
        <main>
          {children}
        </main>
      </div>
      <Footer></Footer>
    </React.Fragment>
  )
}

import React from "react"

export default function Social() {
  return (
    <ul className="flex justify-content-center social-buttons m-y-1">
      <li className="m-x-1">
        <a href="https://www.facebook.com/BeWellFromWithin" target="_blank">
          <img width="18" src="/assets/images/facebook.png" />
        </a>
      </li>
      <li className="m-x-1">
        <a href="https://www.instagram.com/bwellfromwithin/" target="_blank">
          <img width="18" src="/assets/images/instagram.png" />
        </a>
      </li>
      <li className="m-x-1">
        <a href="https://www.pinterest.com/wellfromwithin/" target="_blank">
          <img width="18" src="/assets/images/pinterest.png" />
        </a>
      </li>
      <li className="m-x-1">
        <a href="https://www.linkedin.com/in/alexandrabeane" target="_blank">
          <img width="18" src="/assets/images/linkedin.png" />
        </a>
      </li>
    </ul>
)
}